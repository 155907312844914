import React from 'react'
import Toggle from '@veneer/core/dist/scripts/toggle'
import Button from '@veneer/core/dist/scripts/button'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import List from '@veneer/core/dist/scripts/list'
// import { LogoHpPlus } from '@veneer/core/dist/scripts/logos'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import {
  AppColors,
  SecurityAssessmentStatus,
  BadgeColors
} from '../../static/consts'
import { withDropShadow } from '../../hocs/withDropShadow'
import { IconCloud, ThemeProvider } from '@veneer/core'

const Mobile = ({
  active,
  strings,
  isEnabled,
  isAssessmentInProgress,
  state,
  onToggleMonitoring,
  showSecurityModal,
  dismissSecurityModal,
  isHPX
}) => {
  const warningCssBase = {
    border: `1px solid ${AppColors.warning}`,
    borderRadius: '12px'
  }

  const rightItem = () => {
    if (state.isMonitoringInProgress || isAssessmentInProgress) {
      return <ProgressIndicator data-testid="ss-progressIndicator" />
    }
    if (active === SecurityAssessmentStatus.trueValue) {
      return (
        <Toggle
          on={isEnabled}
          disabled={state.isMonitoringInProgress}
          onChange={showSecurityModal}
          id="toggle"
          data-testid="ss-monitor-toggle"
        />
      )
    }
    return <></>
  }

  const shadowedList = withDropShadow(List, true)
  return (
    <>
      <div>
        <Modal
          id="ss-modal"
          align="center"
          onClose={dismissSecurityModal}
          show={state.isShowingModal}
          title={strings.modalTitle}
          data-testid="ss-monitorModal"
          footer={
            <div className="vn-button-group--responsive">
              <Button
                onClick={isEnabled ? dismissSecurityModal : onToggleMonitoring}
                data-testid="ss-monitorModal-security-button"
              >
                {strings.buttonPrimaryTitle}
              </Button>
              <Button
                appearance="secondary"
                onClick={isEnabled ? onToggleMonitoring : dismissSecurityModal}
                data-testid="ss-monitorModal-removeSecurity-button"
              >
                {strings.buttonSecondaryTitle}
              </Button>
            </div>
          }
        >
          <p>{strings.modalDescription}</p>
        </Modal>
      </div>
      {!isHPX ? (
        <div
          style={
            !isHPX ? (isEnabled ? null : warningCssBase) : isEnabled ? null : {}
          }
        >
          {shadowedList({
            children: (
              <>
                <CardItem
                  customCss={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    paddingBottom: '0'
                  }}
                  leftItem={
                    !isHPX ? (
                      <div style={{ height: '36px' }} />
                    ) : (
                      <IconCloud
                        size={24}
                        color={
                          isEnabled ? BadgeColors.lightGreen : BadgeColors.grey
                        }
                      />
                    )
                  }
                  contentItem={
                    <CardItemContent isSubcontent title={strings.title} />
                  }
                />
                <CardItem
                  contentItem={
                    <CardItemContent
                      isSubcontent
                      content={<>{strings.description}</>}
                    />
                  }
                  rightItem={rightItem()}
                />
              </>
            )
          })}
        </div>
      ) : (
        <div
        // style={
        //   !isHPX ? (isEnabled ? null : warningCssBase) : isEnabled ? null : {}
        // }
        >
          {shadowedList({
            children: (
              <>
                <List>
                  <ThemeProvider shape="sharp">
                    <CardItem
                      customCss={{
                        paddingLeft: '12px',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingRight: '24px',
                        borderRadius: '0',
                        borderTop: '1px solid #d9d9d9'
                      }}
                      leftItem={
                        <IconCloud
                          size={24}
                          color={
                            isEnabled
                              ? BadgeColors.lightGreen
                              : BadgeColors.grey
                          }
                        />
                      }
                      contentItem={
                        <CardItemContent
                          customCss={{ fontSize: '20px', fontWeight: '500' }}
                          // isSubcontent
                          title={strings.title}
                        />
                      }
                    />
                    <List>
                      <List
                        style={{
                          marginLeft: '12px',
                          borderTop: '1px solid #d9d9d9',
                          marginRight: '12px',
                          width: 'auto'
                        }}
                      ></List>
                      <CardItem
                        customCss={{ borderRadius: '0' }}
                        contentItem={
                          <CardItemContent
                            customCss={{ fontSize: '18px', fontWeight: '400' }}
                            content={<>{strings.description}</>}
                          />
                        }
                        rightItem={rightItem()}
                      />
                    </List>
                    <List>
                      <CardItem
                        customCss={{
                          borderBottomLeftRadius: '12px',
                          borderBottomRightRadius: '12px'
                        }}
                        contentItem={
                          <CardItemContent
                            isSubcontent
                            customCss={{ fontSize: '14px', fontWeight: '400' }}
                            content={
                              <>
                                Sync your security assessment to HP Cloud for
                                remote access. Information is exclusively shared
                                with you, ensuring no personal data is stored in
                                the cloud.
                              </>
                            }
                          />
                        }
                      />
                    </List>
                  </ThemeProvider>
                </List>
              </>
            )
          })}
        </div>
      )}
    </>
  )
}

export default Mobile

Mobile.propTypes = {
  active: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isAssessmentInProgress: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  onToggleMonitoring: PropTypes.func.isRequired,
  showSecurityModal: PropTypes.func.isRequired,
  dismissSecurityModal: PropTypes.func.isRequired
}
